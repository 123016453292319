import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  formatDateFromNow,
  hasAdminModificationPriviledge,
  isCurrentUserCreated,
} from "../../utils";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Tooltip from "@material-ui/core/Tooltip";

import { deleteAnswerAPI, getCollectionDetailsByID } from "../../api/api";

import DeleteModal from "../Modals/DeleteModal/DeleteModal";
import { DELETE_RESPONSE_CONFIRM } from "../../constants/Answer";
import { CONFIRM, CANCEL } from "../../constants/ButtonLabel";
import { QUESTION_DETAIL_PAGE } from "../../constants/PageRoutes";
import UserAvatarIcon from "../UserAvatarIcon/UserAvatarIcon";
import { ORIGIN_TANK } from "../../constants";
import { ANSWER_VERIFIED } from "../../constants/Answer";

import "./AnswerPost.scss";

const AnswerPost = (props) => {
  const {
    answerDesp,
    answerId,
    answeredBy,
    createdAt,
    origin,
    userRole,
    creatorId,
    quesId,
    fetchMyPosts,
    currentTeamData,
    colId,
    dp,
    verifiedState,
    toggleEditModal,
  } = props;

  const isAnswerVerified = verifiedState === ANSWER_VERIFIED;

  const [open, setOpen] = useState(false);
  const [collectionName, setCollectionName] = useState("");
  const [collectionColor, setCollectionColor] = useState("");
  const [isGetCollectionExecuted, setisGetCollectionExecuted] = useState(false);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAnswerDelete = (quesID, ansID) => {
    const answerObj = {
      answerId: ansID,
    };
    axios.patch(`${deleteAnswerAPI}${quesID}`, answerObj).then(() => {
      handleClose();
      fetchMyPosts();
    });
  };

  const confirmDelete = () => (
    <DeleteModal
      confirmDeleteMsg={DELETE_RESPONSE_CONFIRM}
      cancelText={CANCEL}
      confirmText={CONFIRM}
      onDelete={() => handleAnswerDelete(quesId, answerId)}
      close={handleClose}
      open={open}
    />
  );

  const fetchCollectionName = useCallback(() => {
    axios.get(`${getCollectionDetailsByID}${colId}`).then((res) => {
      const collectionData = res?.data?.data?.[0];
      if (collectionData !== undefined && collectionData !== null) {
        setCollectionName(collectionData?.collectionName);
        setCollectionColor(collectionData?.colorCode);
      }
    });
  }, [colId]);

  useEffect(() => {
    if (!isGetCollectionExecuted) {
      fetchCollectionName();
      setisGetCollectionExecuted(true);
    }
  }, [isGetCollectionExecuted, fetchCollectionName]);

  return (
    <>
      {confirmDelete()}
      <tr key={answerId}>
        <td className="answer">
          <Link
            to={`${QUESTION_DETAIL_PAGE}/${quesId}`}
            className="answer-wrapper"
          >
            <span
              dangerouslySetInnerHTML={createMarkup(
                answerDesp?.blocks?.[0]?.data?.text.length > 150
                  ? `${answerDesp?.blocks?.[0]?.data?.text.slice(0, 155)}...`
                  : answerDesp?.blocks?.[0]?.data?.text
              )}
            />
            <Tooltip
              className={`tooltip-verification-info ${
                isAnswerVerified ? "verified" : "pending"
              }`}
              title={isAnswerVerified ? "Verified" : "Pending verification"}
            >
              <CheckCircleIcon fontSize="small" />
            </Tooltip>
          </Link>
        </td>
        <td>
          {origin === ORIGIN_TANK ? (
            <img
              src={require("../../assets/images/t-logo.png")}
              alt="origin tank"
              className="origin-img"
            />
          ) : (
            <img
              src={require("../../assets/images/slack-logo.png")}
              alt="origin slack"
              className="origin-img"
            />
          )}
        </td>
        <td>
          <span className="collec-name">
            <span
              className="collec-border"
              style={{
                borderColor: `${collectionColor}`,
                backgroundColor: `${collectionColor}`,
                borderWidth: "2px",
              }}
            ></span>
            {collectionName}
          </span>
        </td>
        <td className="creator">
          <UserAvatarIcon userImage={dp} size="medium" />
          <span>
            {answeredBy}
            <span className="timestamp">{formatDateFromNow(createdAt)}</span>
          </span>
        </td>
        <td>
          {isCurrentUserCreated(creatorId, currentTeamData?.tankUserID) ? (
            <>
              {hasAdminModificationPriviledge(
                userRole,
                creatorId,
                currentTeamData?.tankUserID
              ) ? (
                <DeleteOutlineOutlinedIcon
                  onClick={handleOpen}
                  fontSize="small"
                />
              ) : (
                <>
                  <EditOutlinedIcon
                    onClick={() => toggleEditModal(answerId, quesId)}
                    fontSize="small"
                  />
                  <DeleteOutlineOutlinedIcon
                    onClick={handleOpen}
                    fontSize="small"
                  />
                </>
              )}
            </>
          ) : null}
        </td>
      </tr>
    </>
  );
};

export default AnswerPost;
