export const pricingPlans = (isCountryIndia) => [
  {
    planType: "Free",
    monthlyAmount: "0",
    yearlyAmount: "0",
    features: [
      {
        title: "Collections",
        offer: "Unlimited",
      },
      {
        title: "Documents",
        offer: "Unlimited",
      },
      {
        title: "Slack Integration",
        offer: "Yes",
      },
      {
        title: "Invitation Management",
        offer: "Yes",
      },
      {
        title: "Info Sharing Management",
        offer: "Yes",
      },
      {
        title: "Track Topics of Interest",
        offer: "Yes",
      },
      {
        title: "Verified Content Management",
        offer: "Yes",
      },
      {
        title: "Analytics",
        offer: "Yes",
      },
      {
        title: "Duplicate Detection",
        offer: "Yes",
      },
      {
        title: "Content Gap Analysis",
        offer: "Yes",
      },
    ],
  },
  {
    planType: "Basic",
    monthlyAmount: isCountryIndia ? "30" : "4",
    yearlyAmount: isCountryIndia ? "20" : "2",
    features: [
      {
        title: "Collections",
        offer: "50",
      },
      {
        title: "Documents",
        offer: "Unlimited",
      },
      {
        title: "Slack Integration",
        offer: "Yes",
      },
      {
        title: "Invitation Management",
        offer: "Yes",
      },
      {
        title: "Info Sharing Management",
        offer: "Yes",
      },
      {
        title: "Track Topics of Interest",
        offer: "Yes",
      },
      {
        title: "Verified Content Management",
        offer: "Yes",
      },
      {
        title: "Analytics",
        offer: "No",
      },
      {
        title: "Duplicate Detection",
        offer: "No",
      },
      {
        title: "Content Gap Analysis",
        offer: "No",
      },
    ],
  },
  {
    planType: "Pro",
    monthlyAmount: isCountryIndia ? "60" : "5",
    yearlyAmount: isCountryIndia ? "50" : "3",
    features: [
      {
        title: "Collections",
        offer: "Unlimited",
      },
      {
        title: "Documents",
        offer: "Unlimited",
      },
      {
        title: "Slack Integration",
        offer: "Yes",
      },
      {
        title: "Invitation Management",
        offer: "Yes",
      },
      {
        title: "Info Sharing Management",
        offer: "Yes",
      },
      {
        title: "Track Topics of Interest",
        offer: "Yes",
      },
      {
        title: "Verified Content Management",
        offer: "Yes",
      },
      {
        title: "Analytics",
        offer: "Yes",
      },
      {
        title: "Duplicate Detection",
        offer: "Yes",
      },
      {
        title: "Content Gap Analysis",
        offer: "Yes",
      },
    ],
  },
];

export const planFeatures = [
  {
    title: "Collections",
    free: "Unlimited",
    basic: "50",
    pro: "Unlimited",
  },
  {
    title: "Documents",
    free: "Unlimited",
    basic: "Unlimited",
    pro: "Unlimited",
  },
  {
    title: "Slack Integration",
    free: "Yes",
    basic: "Yes",
    pro: "Yes",
  },
  {
    title: "Invitation Management",
    free: "Yes",
    basic: "Yes",
    pro: "Yes",
  },
  {
    title: "Info Sharing Management",
    free: "Yes",
    basic: "Yes",
    pro: "Yes",
  },
  {
    title: "Track Topics of Interest",
    free: "Yes",
    basic: "Yes",
    pro: "Yes",
  },
  {
    title: "Verified Content Management",
    free: "Yes",
    basic: "Yes",
    pro: "Yes",
  },
  {
    title: "Analytics",
    free: "Yes",
    basic: "No",
    pro: "Yes",
  },
  {
    title: "Duplicate Detection",
    free: "Yes",
    basic: "No",
    pro: "Yes",
  },
  {
    title: "Content Gap Analysis",
    free: "Yes",
    basic: "No",
    pro: "Yes",
  },
];
