import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import CodeTool from "@editorjs/code";
import InlineCode from "@editorjs/inline-code";
import Underline from "@editorjs/underline";
import Quote from "@editorjs/quote";
// import ImageTool from "@editorjs/image";
import Embed from "@editorjs/embed";

const TankEditorTool = ({ editorContentChange, setEditor, prefillData }) => {
  let editor = { isReady: false };
  const editorRef = useRef(null);
  const editorInstance = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      editorInstance.current = new EditorJS({
        holder: editorRef.current,
        placeholder: "Type text or paste a link",
        tools: {
          header: {
            class: Header,
            inlineToolbar: ["link"],
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          underline: Underline,
          inlineCode: {
            class: InlineCode,
          },
          code: CodeTool,
          quote: Quote,
          table: Table,
          embed: Embed,
          // image: {
          //   class: ImageTool,
          //   config: {
          //     endpoints: {
          //       byFile: "http://localhost:8008/uploadFile", // Your backend file uploader endpoint
          //       byUrl: "http://localhost:8008/fetchUrl", // Your endpoint that provides uploading by Url
          //     },
          //   },
          // },
        },
        onChange: editorContentChange,
        onReady: () => {
          setEditor(editorInstance.current);
        },
        data: prefillData,
      });
    }

    return () => {
      if (editorInstance.current) {
        editorInstance?.current?.destroy();
        editorInstance.current = null;
      }
    };
  }, [editorContentChange, prefillData, setEditor]);

  return (
    <div className="TankEditorTool">
      <div ref={editorRef} id="editorjs"></div>
    </div>
  );
};

export default TankEditorTool;
