export const BASIC_MONTHLY_PLAN = "Basic_monthly";
export const BASIC_MONTHLY_PLAN_PRICE_IN = 30;
export const BASIC_MONTHLY_PLAN_PRICE = 4;

export const BASIC_YEARLY_PLAN = "Basic_yearly";
export const BASIC_YEARLY_PLAN_PRICE_IN = 20;
export const BASIC_YEARLY_PLAN_PRICE = 2;

export const PRO_MONTHLY_PLAN = "Pro_monthly";
export const PRO_MONTHLY_PLAN_PRICE_IN = 60;
export const PRO_MONTHLY_PLAN_PRICE = 5;

export const PRO_YEARLY_PLAN = "Pro_yearly";
export const PRO_YEARLY_PLAN_PRICE_IN = 50;
export const PRO_YEARLY_PLAN_PRICE = 3;
