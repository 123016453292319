import React, { useState, useEffect } from "react";
import axios from "axios";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

import TankEditor from "../../TankEditor/TankEditor";
import TankEditorTool from "../../TankEditor/Editor";
import Modal from "../Modal/Modal";

import { getCollectionDetailsByID } from "../../../api/api";
import { convertToHTMLString } from "../../TankEditor/convertToHtmlString";
import {
  ADD_NEW_QUERY,
  ENTER_QUERY_TITLE,
  SELECT_COLLECTION,
} from "../../../constants/Question";
import { CANCEL, CREATE } from "../../../constants/ButtonLabel";

import InputField from "../../InputField/InputField";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";

import "./NewQuestionModal.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NewQuestionModal = (props) => {
  const {
    open,
    close,
    addQues,
    handleQuestionSaveDraft,
    colId,
    teamMembers,
    teamMemberNameKey,
    handleOptionSelection,
    collectionData,
    feedbackMsgEnabled,
    queryLengthError = false,
  } = props;
  const classes = useStyles();
  const [editorContent, setEditorContent] = useState("");
  // const [isQuestionContentAdded, setIsQuestionContentAdded] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [collecId, setCollecId] = useState("");
  const [editorTool, setEditorTool] = useState();

  const divStyle = {
    display: open ? "flex" : "none",
  };

  // let isSubmitDisabled = true;
  // let isDraftBtnDisabled = true;

  const getCollectionDetails = (id) => {
    axios.get(`${getCollectionDetailsByID}${id}`).then((res) => {
      const resData =
        res &&
        res.data &&
        res.data.data &&
        res.data.data[0] &&
        res.data.data[0].collectionName;
      setCollectionName(resData);
    });
  };

  useEffect(() => {
    if (open === true) {
      setEditorContent("");
      setQuestionTitle("");
    }
  }, [open]);

  useEffect(() => {
    if (colId) {
      setCollecId(colId);
      getCollectionDetails(colId);
    }
  }, [colId]);

  // const handleEditorChange = (content, editor) => {
  //   setEditorContent(content);

  //   if (content.trim() === "") {
  //     setIsQuestionContentAdded(false);
  //   } else {
  //     setIsQuestionContentAdded(true);
  //   }
  // };

  const handleCollectionSelectChange = (event, child) => {
    setCollectionName(event.target.value);
    setCollecId(child.key.replace(".$", ""));
  };

  // if (
  //   isQuestionContentAdded === true &&
  //   questionTitle.trim() !== "" &&
  //   collectionName !== ""
  // ) {
  //   isSubmitDisabled = false;
  // }

  // if (
  //   isQuestionContentAdded === true ||
  //   questionTitle.trim() !== "" ||
  //   collectionName !== ""
  // ) {
  //   isDraftBtnDisabled = false;
  // }

  const handleEditorContent = (isDraft = false) => {
    editorTool
      ?.save()
      .then((outputData) => {
        const parsedData = convertToHTMLString(outputData?.blocks);
        if (!isDraft) {
          addQues(collecId, questionTitle, {
            questionDesc: parsedData,
            savedData: outputData,
          });
        } else {
          handleQuestionSaveDraft(
            questionTitle,
            { questionDesc: parsedData, savedData: outputData },
            collecId
          );
        }
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  return (
    <div className="new-ques-modal">
      <Modal
        // isDisabled={isSubmitDisabled}
        cancelText={CANCEL}
        provisionType={CREATE}
        handleProvisioning={() => handleEditorContent(false)}
        modalTitle={ADD_NEW_QUERY}
        modalDisplay={divStyle}
        close={close}
        isDraftBtn={true}
        // isDraftBtnDisabled={isDraftBtnDisabled}
        handleSaveAsDraft={() => handleEditorContent(true)}
        feedbackMsgEnabled={feedbackMsgEnabled}
        feedbackMsg={"Query added successfully"}
        queryLengthError={queryLengthError}
      >
        <InputField
          labelText={ENTER_QUERY_TITLE}
          inputValue={questionTitle}
          type={"text"}
          inputTag={"input"}
          handleOnChange={setQuestionTitle}
        />
        <InputField labelText={SELECT_COLLECTION} />
        {collectionData && collectionData.length ? (
          <FormControl className={`${classes.formControl} dropdown-comp`}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={collectionName}
              onChange={handleCollectionSelectChange}
              className="selected-option"
              defaultValue={"no colection"}
            >
              {collectionData &&
                collectionData.map((col) => (
                  <MenuItem
                    key={col.collectionId}
                    className="dropdown-menu-item"
                    value={col.collectionName}
                  >
                    {col.collectionName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <div className="no-collec-found">
            <InputField
              type={"text"}
              inputTag={"input"}
              placeholderText={"No collection found"}
            />
          </div>
        )}
        <SearchDropdown
          label={"Add mentions"}
          options={teamMembers}
          teamMemberNameKey={teamMemberNameKey}
          handleOptionSelection={handleOptionSelection}
        />
        {/* <TankEditor content={editorContent} editorChange={handleEditorChange} /> */}
        <TankEditorTool
          // editorContentChange={handleEditorChange}
          setEditor={setEditorTool}
          editorTool={editorTool}
        />
      </Modal>
    </div>
  );
};

export default NewQuestionModal;
