const listParentElement = (style) => {
  if (style === "unordered" || style === "checklist") return "ul";
  return "ol";
};

export const convertToHTMLString = (blocks = []) => {
  let convertedHtml = "";
  blocks.map((block) => {
    switch (block?.type) {
      case "header":
        convertedHtml += `<h${block?.data?.level}>${block?.data?.text}</h${block?.data?.level}>`;
        break;
      case "embded":
        convertedHtml += `<div><iframe width="560" height="315" src="${block?.data?.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
        break;
      case "paragraph":
        convertedHtml += `<p>${block?.data?.text}</p>`;
        break;
      case "delimiter":
        convertedHtml += "<hr />";
        break;
      case "image":
        convertedHtml += `<img class="img-fluid" src="${block?.data?.file.url}" title="${block?.data?.caption}" /><br /><em>${block?.data?.caption}</em>`;
        break;
      case "list":
        const listTag = listParentElement(block?.data?.style);
        convertedHtml += `<${listTag}>${block?.data?.items
          .map(
            (listItem) =>
              `<li>${
                block?.data?.style === "checklist"
                  ? `<input type="checkbox" checked=${listItem?.meta?.checked} /><label>${listItem?.content}</label>`
                  : listItem?.content
              }</li>`
          )
          .join("")}</${listTag}>`;
        break;
      case "table":
        convertedHtml += `
          <table>
            ${
              block?.data?.withHeadings
                ? `<thead><tr>${block?.data?.content?.[0]
                    ?.map((headingRow) => `<th>${headingRow}</th>`)
                    .join("")}</tr></thead>`
                : null
            }
            <tbody>
              ${block?.data?.content
                .map((row, idx) =>
                  block?.data?.withHeadings && idx === 0
                    ? null
                    : `<tr>${row
                        ?.map((rowText) => `<td>${rowText}</td>`)
                        .join("")}</tr>`
                )
                .join("")}
            </tbody>
          </table>
        `;
        break;
      case "code":
        convertedHtml += `${block?.data?.code}`;
        break;
      case "quote":
        convertedHtml += `<blockquote class=${block?.data?.alignment}}>
          <p>${block?.data?.text}</p>
          ${
            block?.data?.caption
              ? `<footer>${block?.data?.caption}</footer>`
              : null
          }
        </blockquote>`;
        break;
      default:
        convertedHtml = "";
        break;
    }
    return convertedHtml;
  });
  return convertedHtml;
};
