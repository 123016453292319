import React, { useState, useEffect } from "react";

import Answer from "../../components/Answer/Answer";
import AddAnswerModal from "../../components/Modals/AddAnswerModal/AddAnswerModal";
import EditAnswerModal from "../../components/Modals/EditAnswerModal/EditAnswerModal";
import Loader from "../../components/Loader/Loader";
import BackButton from "../../components/BackButton/BackButton";
import Modal from "../../components/Modals/Modal/Modal";

import axios from "axios";

import {
  questionDetails,
  deleteAnswerAPI,
  addAnswer,
  getAnswerByID,
  updateAnswerByID,
  getCollectionDetailsByID,
} from "../../api/api";

import { ADD_RESPONSE_TITLE, NO_RESPONSES } from "../../constants/Answer";
import { ASKED_BY } from "../../constants/Question";
import { DASHBOARD_PAGE } from "../../constants/PageRoutes";

import {
  formatDateDMY,
  saveAnswerAsDraft,
  isEmptyObject,
  isOk,
} from "../../utils";

import Fab from "@material-ui/core/Fab";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import "./QuestionDetailPage.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paperStyle: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

const QuestionDetailPage = (props) => {
  const {
    match: {
      params: { id },
    },
    updateQuestion,
    currentTeamData,
    history,
  } = props;

  const modalDisplay = {
    display: "flex",
  };

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [questionDetail, setQuestionDetails] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [ansID, setAnsID] = useState("");
  const [queID, setQueID] = useState("");
  const [ansDesc, setAnsDesc] = useState({});
  const [quesTitle, setQuesTitle] = useState("");
  const [ansAdded, setAnsAdded] = useState(false);
  const [questionCollecId, setQuestionCollecId] = useState("");
  const [editorTool, setEditorTool] = useState();
  const [isFetchingSavedAnswer, setIsFetchingSavedAnswer] = useState(false);

  async function fetchQuestions(quesID) {
    await axios.get(`${questionDetails}${quesID}`).then((res) => {
      const resData = res?.data?.data?.[0];
      if (resData) {
        const questionCollectionId = resData?.collectionId;
        setQuestionCollecId(questionCollectionId);
        setQuestionDetails(resData);
        setQuesTitle(resData?.quesTitle);
        if (questionCollectionId) {
          axios
            .get(`${getCollectionDetailsByID}${questionCollectionId}`)
            .then((res) => {
              if (isOk(res)) {
                const collectionName = res?.data?.data?.[0]?.collectionName;
                const collectionColor = res?.data?.data?.[0]?.colorCode;
                setQuestionDetails({
                  ...resData,
                  quesCollectionName: collectionName,
                  quesCollectionColor: collectionColor,
                });
              }
            })
            .finally(() => setLoading(false));
        }
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchQuestions(id);
  }, [id]);

  const renderModal = () => {
    setIsModal(!isModal);
  };

  const toggleEditModal = (ansId, qId) => {
    setIsEditModalOpen(!isEditModalOpen);
    setAnsID(ansId);
    setQueID(qId);
    handleAnswerEdit(qId, ansId);
  };

  const closeEditAnswerModal = () => {
    setIsEditModalOpen(false);
    setIsFetchingSavedAnswer(false);
  };

  const handleAnswerDelete = (quesID, ansID) => {
    const answerObj = {
      answerId: ansID,
    };
    axios.patch(`${deleteAnswerAPI}${quesID}`, answerObj).then(() => {
      fetchQuestions(quesID);
      updateQuestion(id);
    });
  };

  const handleAnswerEdit = (quesID, ansID) => {
    setIsFetchingSavedAnswer(true);
    if (isEditModalOpen === true) {
      setAnsDesc({});
    }

    axios
      .get(`${getAnswerByID}${quesID}?answerId=${ansID}`)
      .then((res) => {
        setAnsDesc(res?.data?.[0]?.savedData);
      })
      .finally(() => setIsFetchingSavedAnswer(false));
  };

  const handleAnswerUpdate = (quesId, ansId, { updatedAnswer, savedData }) => {
    const updatedAns = {
      answerDesp: updatedAnswer,
      savedData,
    };

    axios
      .patch(
        `${updateAnswerByID}?questID=${quesId}&answerId=${ansId}`,
        updatedAns
      )
      .then(() => fetchQuestions(quesId));

    toggleEditModal(ansId, quesId);
  };

  const handleAnswerAdd = async ({ answerDesc, savedData }, userName) => {
    const { userTankID } = props;
    const addAnswerObj = {
      answerDesp: answerDesc,
      answeredBy: userName,
      tankUserID: userTankID,
      savedData,
    };

    try {
      await axios.patch(`${addAnswer}${id}`, addAnswerObj);

      // renderModal();

      fetchQuestions(id);
      updateQuestion(id);
      setAnsAdded(true);
    } catch (err) {
      console.log("Something went wrong", err);
    }
  };

  const handleAnswerSaveDraft = (
    { answerDesc, savedData },
    actionType = ""
  ) => {
    const { userTankID, userClientID, creatorID } = props;
    saveAnswerAsDraft(
      answerDesc,
      userTankID,
      userClientID,
      creatorID,
      id,
      quesTitle,
      savedData
    );
    if (actionType === "add") {
      renderModal();
    } else {
      setIsEditModalOpen(!isEditModalOpen);
    }
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  if (!questionDetail) return null;

  const questionTitle = questionDetail?.quesTitle;
  const questionDescription = questionDetail?.quesDesp;
  const askedBy = questionDetail?.userName;
  const askedOn = questionDetail?.createdAt;
  const answers = questionDetail?.answers;
  const collectionTitle = questionDetail?.quesCollectionName;
  const collectionColorCode = questionDetail?.quesCollectionColor;

  return (
    <div className="question-detail-page page-loader-pos dashboard-vert-gutters">
      {isModal && (
        <AddAnswerModal
          currentTeamData={currentTeamData}
          addAns={handleAnswerAdd}
          open={isModal}
          close={renderModal}
          handleAnswerSaveDraft={handleAnswerSaveDraft}
          feedbackMsgEnabled={ansAdded}
          feedbackMsg={"Response added successfully"}
          setEditorTool={setEditorTool}
          editorTool={editorTool}
        />
      )}
      {!isEmptyObject(ansDesc) && (
        <EditAnswerModal
          open={isEditModalOpen}
          close={closeEditAnswerModal}
          ansID={ansID}
          queID={queID}
          setAnswer={ansDesc}
          updatedAns={handleAnswerUpdate}
          handleAnswerSaveDraft={handleAnswerSaveDraft}
          setEditorTool={setEditorTool}
          editorTool={editorTool}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          {!isEmptyObject(questionDetail) ? (
            <>
              <BackButton questionCollecId={questionCollecId} />
              <div className="ques-detail-container">
                {questionTitle ? (
                  <div className="ques-container">
                    <h1 className="ques-collection-title">
                      <div
                        className="collec-border"
                        style={{
                          borderColor: collectionColorCode,
                          backgroundColor: collectionColorCode,
                          borderWidth: "2px",
                        }}
                      ></div>
                      {collectionTitle}
                    </h1>
                    <h2 className="ques-title">{questionTitle}</h2>
                    <h4
                      className="ques-description"
                      dangerouslySetInnerHTML={createMarkup(
                        questionDescription
                      )}
                    />
                    <div className="author-info">
                      <h5>
                        {ASKED_BY}: {askedBy} on {formatDateDMY(askedOn)}
                      </h5>
                    </div>
                  </div>
                ) : null}
                <div className="answers-container">
                  {answers && answers?.length ? (
                    answers.map((ans) => {
                      return (
                        <Answer
                          {...props}
                          answerData={ans}
                          quesID={id}
                          deleteAnswer={handleAnswerDelete}
                          openEditModal={toggleEditModal}
                          isFetchingSavedAnswer={isFetchingSavedAnswer}
                        />
                      );
                    })
                  ) : (
                    <h2 className="no-answers" variant="h4">
                      {NO_RESPONSES}
                    </h2>
                  )}
                </div>
              </div>
              <div className={`${classes.paperStyle} add-answer-btn-wrapper`}>
                <Paper elevation={3} className="add-answer-btn">
                  <Fab
                    color="secondary"
                    aria-label="edit"
                    variant="extended"
                    onClick={renderModal}
                  >
                    <AddCircleIcon fontSize="small" />
                    {ADD_RESPONSE_TITLE}
                  </Fab>
                </Paper>
              </div>
            </>
          ) : (
            <div className="query-not-available">
              <Modal
                close={() => history.push(DASHBOARD_PAGE)}
                modalDisplay={modalDisplay}
                isDisabled={false}
                provisionType={"go to dashboard"}
                handleProvisioning={() => history.push(DASHBOARD_PAGE)}
              >
                <div className="tank-modal delete">
                  <h3 className="confirm-delete-msg">
                    {
                      "The content you’re looking for does not exist. Please check with author."
                    }
                  </h3>
                </div>
              </Modal>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionDetailPage;
