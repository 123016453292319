import React, { useState, useEffect } from "react";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import BillingForm from "./BillingForm/BillingForm";

import { getAllUsersFromSpace, getCountry } from "../../../api/api";

import {
  BASIC_MONTHLY_PLAN_PRICE_IN,
  BASIC_MONTHLY_PLAN_PRICE,
  BASIC_YEARLY_PLAN_PRICE_IN,
  BASIC_YEARLY_PLAN_PRICE,
  PRO_MONTHLY_PLAN_PRICE_IN,
  PRO_MONTHLY_PLAN_PRICE,
  PRO_YEARLY_PLAN_PRICE_IN,
  PRO_YEARLY_PLAN_PRICE,
  PRO_YEARLY_PLAN,
} from "../../../constants/Billing";
import { BASIC_PLAN_FEATURES, PRO_PLAN_FEATURES } from "../../../constants";
import { STRIPE_PUBLISHABLE_KEY } from "./stripe-constants";

import "./Billing.scss";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const MONTHLY_PLAN = "monthly";
const YEARLY_PLAN = "yearly";

const BASIC_PLAN = "Basic";
const PRO_PLAN = "Pro";

const BillingPage = (props) => {
  const [selectedPlan, setSelectedPlan] = useState(
    `${BASIC_PLAN}_${MONTHLY_PLAN}`
  );
  const [activePlan, setActivePlan] = useState(BASIC_PLAN);

  const [basicPlan, setBasicPlan] = useState(MONTHLY_PLAN);
  const [proPlan, setProPlan] = useState(MONTHLY_PLAN);

  const [upgradePrice, setUpgradePrice] = useState(0);
  const [isCountryIndia, setIsCountryIndia] = useState(false);

  const [teamMembersCount, setTeamMembersCount] = useState([]);
  const [subscribedPlan, setSubscribedPlan] = useState({
    planName: BASIC_PLAN,
    planDuration: MONTHLY_PLAN,
  });

  useEffect(() => {
    const { currentTeamData } = props;
    const currSpaceID = currentTeamData?.spaceID;

    axios.get(`${getAllUsersFromSpace}${currSpaceID}`).then((res) => {
      const resData = res?.data?.data;
      if (resData) {
        setTeamMembersCount(resData?.length);
      }
    });
  }, []);

  const handleBasicPlanChange = (plan) => {
    plan === MONTHLY_PLAN
      ? setBasicPlan(MONTHLY_PLAN)
      : setBasicPlan(YEARLY_PLAN);
  };

  const handleProPlanChange = (plan) => {
    plan === MONTHLY_PLAN ? setProPlan(MONTHLY_PLAN) : setProPlan(YEARLY_PLAN);
  };

  const handlePlanSelection = (plan, period) => {
    setSelectedPlan(`${plan}_${period}`);
  };

  const fetchCountry = async () => {
    try {
      const response = await axios.get(getCountry);
      const countryName = response?.data?.country || null;
      setIsCountryIndia(countryName === "India");
    } catch (error) {
      console.error("Error fetching country information:", error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if (selectedPlan.includes(BASIC_PLAN)) {
      setActivePlan(BASIC_PLAN);
      if (selectedPlan.includes("monthly")) {
        setUpgradePrice(BASIC_MONTHLY_PLAN_PRICE_IN);
      } else {
        setUpgradePrice(BASIC_YEARLY_PLAN_PRICE_IN);
      }
    } else {
      setActivePlan(PRO_PLAN);
      if (selectedPlan.includes("monthly")) {
        setUpgradePrice(PRO_MONTHLY_PLAN_PRICE_IN);
      } else {
        setUpgradePrice(PRO_YEARLY_PLAN_PRICE_IN);
      }
    }
  }, [selectedPlan]);

  console.log(subscribedPlan);

  const handleSubscribedPlanChange = (
    subscribedPlanName,
    subscribedPlanDuration
  ) => {
    setSubscribedPlan({
      planName: subscribedPlanName,
      planDuration: subscribedPlanDuration,
    });
  };

  const MONTHLY_PLAN_BASIC = isCountryIndia
    ? BASIC_MONTHLY_PLAN_PRICE_IN
    : BASIC_MONTHLY_PLAN_PRICE;

  const YEARLY_PLAN_BASIC = isCountryIndia
    ? BASIC_YEARLY_PLAN_PRICE_IN
    : BASIC_YEARLY_PLAN_PRICE;

  const MONTHLY_PLAN_PRO = isCountryIndia
    ? PRO_MONTHLY_PLAN_PRICE_IN
    : PRO_MONTHLY_PLAN_PRICE;

  const YEARLY_PLAN_PRO = isCountryIndia
    ? PRO_YEARLY_PLAN_PRICE_IN
    : PRO_YEARLY_PLAN_PRICE;

  const billingPlans = (
    <Grid container spacing={4}>
      <Grid item lg={6}>
        <div
          className={`plan-details ${
            subscribedPlan?.planName === BASIC_PLAN ? "active-plan" : ""
          }`}
        >
          <div className="plan-duration">
            <Button
              onClick={() => {
                handleBasicPlanChange(MONTHLY_PLAN);
                handleSubscribedPlanChange(BASIC_PLAN, MONTHLY_PLAN);
              }}
              className={`primary-btn mini ${
                basicPlan === MONTHLY_PLAN ? "active" : ""
              }`}
            >
              Monthly
            </Button>
            <Button
              onClick={() => {
                handleBasicPlanChange(YEARLY_PLAN);
                handleSubscribedPlanChange(BASIC_PLAN, YEARLY_PLAN);
              }}
              className={`primary-btn mini ${
                basicPlan === YEARLY_PLAN ? "active" : ""
              }`}
            >
              Yearly
            </Button>
          </div>
          <h2>Basic</h2>
          <p className="selected-plan-price">
            {isCountryIndia ? <span>&#8377;</span> : "$"}
            {basicPlan === MONTHLY_PLAN
              ? `${MONTHLY_PLAN_BASIC}.00`
              : `${YEARLY_PLAN_BASIC}.00`}{" "}
            <p className="plan-period">per member, per month</p>
          </p>
          {/* <Button
            variant="contained"
            color="secondary"
            className="primary-btn"
            onClick={() => handlePlanSelection(BASIC_PLAN, basicPlan)}
          >
            Upgrade
          </Button> */}
          <div className="features-list">
            {BASIC_PLAN_FEATURES.map((feature) => (
              <p>{feature}</p>
            ))}
          </div>
        </div>
      </Grid>
      <Grid item lg={6}>
        <div
          className={`plan-details ${
            subscribedPlan?.planName === PRO_PLAN ? "active-plan" : ""
          }`}
        >
          <div className="plan-duration">
            <Button
              onClick={() => {
                handleProPlanChange(MONTHLY_PLAN);
                handleSubscribedPlanChange(PRO_PLAN, MONTHLY_PLAN);
              }}
              className={`primary-btn mini ${
                proPlan === MONTHLY_PLAN ? "active" : ""
              }`}
            >
              Monthly
            </Button>
            <Button
              onClick={() => {
                handleProPlanChange(YEARLY_PLAN);
                handleSubscribedPlanChange(PRO_PLAN, YEARLY_PLAN);
              }}
              className={`primary-btn mini ${
                proPlan === YEARLY_PLAN ? "active" : ""
              }`}
            >
              Yearly
            </Button>
          </div>
          <h2>Pro</h2>
          <p className="selected-plan-price">
            {isCountryIndia ? <span>&#8377;</span> : "$"}
            {proPlan === MONTHLY_PLAN
              ? `${MONTHLY_PLAN_PRO}.00`
              : `${YEARLY_PLAN_PRO}.00`}{" "}
            <p className="plan-period">per member, per month</p>
          </p>
          {/* <Button
            variant="contained"
            color="secondary"
            className="primary-btn"
            onClick={() => handlePlanSelection(PRO_PLAN, proPlan)}
          >
            Upgrade
          </Button> */}
          <div className="features-list">
            {PRO_PLAN_FEATURES.map((feature) => (
              <p>{feature}</p>
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div className="billing-page screen-max-width page-top">
      {billingPlans}
      <Elements stripe={stripePromise}>
        <BillingForm
          selectedPlan={selectedPlan}
          upgradePrice={upgradePrice}
          teamMembersCount={teamMembersCount}
          basicPlan={basicPlan}
          proPlan={proPlan}
          subscribedPlan={subscribedPlan}
          {...props}
        />
      </Elements>
    </div>
  );
};

export default BillingPage;
