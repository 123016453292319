import React, { Component } from "react";

import {
  questionsList,
  deleteQuestionAPI,
  questionDetails,
  addAnswer,
  updateQuestionByID,
  getFollowedQueries,
  followQuery,
  unfollowQuery,
} from "../../api/api";

import {
  saveQuestionAsDraft,
  saveAnswerAsDraft,
  isEmptyObject,
} from "../../utils";

import {
  formatDateDMY,
  formatDateFromNow,
  mostRecentEntriesFirst,
} from "../../utils";

import axios from "axios";

import Question from "../../components/Question/Question";
import NewQuestionButton from "../../components/NewQuestionButton/NewQuestionButton";

import AddAnswerModal from "../../components/Modals/AddAnswerModal/AddAnswerModal";
import EditQuestionModal from "../../components/Modals/EditQuestionModal/EditQuestionModal";
import MoveQuestionModal from "../../components/Modals/MoveQuestionModal/MoveQuestionModal";

import Shimmer from "../../components/Shimmer";
import TableShimmer from "../../components/Shimmer/TableShimmer";

import { RESPONSE, RESPONSES } from "../../constants/Answer";
import { ADD_NEW_QUERY, NO_QUERIES } from "../../constants/Question";
import { ORIGIN_TANK } from "../../constants";

import "./CollectionQuestionsPage.scss";

class CollectionsPage extends Component {
  state = {
    questionsData: [],
    loading: true,
    collecId: "",
    answerModalOpen: false,
    questionId: "",
    openEditQuestionModal: false,
    quesTitle: "",
    quesDesc: {},
    quesCollectionId: "",
    followedData: false,
    collectionTitle: "",
    collectionDescription: "",
    collectionColor: "",
    collectionCreator: "",
    collectionCreatedAt: "",
    collectionUpdatedAt: "",
    expanded: false,
    showMoveQuestionModal: false,
    moveQuestionId: "",
    isDeleteInProgress: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.updatedQues !== this.props.updatedQues) {
      this.setState({ questionsData: this.props.updatedQues });
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const { userTankID, userClientID, setCollectionIdFromCollectionPage } =
      this.props;

    this.setState({ collecId: id }, () => {
      this.fetchQuestionsFromCollectionId(this.state.collecId);
      setCollectionIdFromCollectionPage(this.state.collecId);
    });

    // this.fetchFollowedQueries(userTankID, userClientID);
  }

  fetchFollowedQueries = async (tankID, spaceID) => {
    const followBody = {
      tankUserID: tankID,
      spaceID: spaceID,
    };

    const followedQueries = await axios.post(getFollowedQueries, followBody);

    followedQueries?.data?.length
      ? this.setState({ followedData: true })
      : this.setState({ followedData: false });
  };

  fetchQuestionsFromCollectionId = async (colId) => {
    const { userTankID, userClientID } = this.props;

    const body = {
      collectionId: colId,
      tankUserID: userTankID,
      spaceID: userClientID,
    };

    const data = await axios.post(questionsList, body);

    const res = data?.data;
    const queriesData = res;
    const resCollectionData = res?.collectionDetails?.[0];

    this.setState(
      {
        questionsData: queriesData,
        collectionTitle: resCollectionData?.collectionName,
        collectionDescription: resCollectionData?.collectionDesp,
        collectionColor: resCollectionData?.colorCode,
        collectionCreator: resCollectionData?.creatorID,
        collectionCreatedAt: resCollectionData?.createdAt,
        collectionUpdatedAt: resCollectionData?.updatedAt,
      },
      () => this.setState({ loading: false })
    );
  };

  handleQuestionPrefill = async (id) => {
    if (this.state.openEditQuestionModal === false) {
      this.setState({
        quesTitle: "",
        quesDesc: {},
      });
    }
    const queryData = await axios.get(`${questionDetails}${id}`);
    const query = queryData?.data?.data?.[0];

    if (query) {
      this.setState({
        quesTitle: query?.quesTitle,
        quesDesc: query?.metadata?.[0]?.savedData,
        collecId: query?.collectionId,
      });
    }
  };

  handleAnswerModalOpen = (quesID, quesTitle) => {
    this.setState({
      answerModalOpen: true,
      questionId: quesID,
      quesTitle: quesTitle,
    });
  };

  handleAnswerModalClose = () => {
    this.setState({
      answerModalOpen: false,
      questionId: "",
    });
  };

  handleAnswerAdd = async ({ answerDesc, savedData }, userName) => {
    const { userTankID } = this.props;
    const addAnswerObj = {
      answerDesp: answerDesc,
      answeredBy: userName,
      tankUserID: userTankID,
      savedData,
    };

    await axios.patch(`${addAnswer}${this.state.questionId}`, addAnswerObj);

    this.fetchQuestionsFromCollectionId(this.state.collecId);
    this.handleAnswerModalClose();
  };

  handleAnswerSaveDraft = ({ answerDesc, savedData }) => {
    const { userTankID, userClientID, creatorID } = this.props;
    const { questionId, quesTitle } = this.state;
    saveAnswerAsDraft(
      answerDesc,
      userTankID,
      userClientID,
      creatorID,
      questionId,
      quesTitle,
      savedData
    );
    this.handleAnswerModalClose();
  };

  handleQuestionDelete = (quesID, colId) => {
    this.setState({ isDeleteInProgress: true });
    axios.delete(`${deleteQuestionAPI}${quesID}`).then(() => {
      this.setState({ isDeleteInProgress: false });
      this.fetchQuestionsFromCollectionId(colId);
    });
  };

  closeEditQuestionModal = () => {
    this.setState({
      openEditQuestionModal: false,
      questionId: "",
      quesCollectionId: "",
    });
  };

  renderEditQuestionModal = (id, collecId) => {
    this.handleQuestionPrefill(id);
    this.setState({
      openEditQuestionModal: true,
      questionId: id,
      quesCollectionId: collecId,
    });
  };

  handleEditQuestion = (queTitle, { quesDesc, savedData }, quesCollecId) => {
    const updatedQuestionData = {
      quesTitle: queTitle,
      quesDesp: quesDesc,
      collectionId: quesCollecId,
      // savedData,
      metadata: [{ savedData }],
    };

    axios
      .patch(
        `${updateQuestionByID}${this.state.questionId}`,
        updatedQuestionData
      )
      .then(() =>
        this.fetchQuestionsFromCollectionId(this.state.quesCollectionId)
      );

    this.setState({ openEditQuestionModal: !this.state.openEditQuestionModal });
  };

  handleExpandCollapse = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleQuestionSaveDraft = (
    queTitle,
    { quesDesc, savedData },
    quesCollecId
  ) => {
    const { userTankID, userClientID, creatorID, selectedMembers } = this.props;
    const { quesCollectionId } = this.state;
    let colId = "";
    if (quesCollecId === "") {
      colId = quesCollectionId;
    } else {
      colId = quesCollecId;
    }
    saveQuestionAsDraft(
      queTitle,
      quesDesc,
      colId,
      userTankID,
      creatorID,
      userClientID,
      selectedMembers,
      savedData
    );
    this.setState({ openEditQuestionModal: !this.state.openEditQuestionModal });
  };

  handleFollowQuestion = (quesId) => {
    const { userTankID, userClientID } = this.props;
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios.post(followQuery, body);
  };

  handleUnfollowQuestion = (quesId) => {
    const { userTankID, userClientID } = this.props;
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios.post(unfollowQuery, body);
  };

  closeMoveQuestionModal = () => {
    this.setState({ showMoveQuestionModal: false, moveQuestionId: "" });
  };

  openMoveQuestionModal = (id) => {
    this.setState({ showMoveQuestionModal: true, moveQuestionId: id });
  };

  handleMoveQuestionToCollection = (id) => {
    const updatedQuestionData = {
      collectionId: id,
    };

    axios
      .patch(
        `${updateQuestionByID}${this.state.moveQuestionId}`,
        updatedQuestionData
      )
      .then(() => this.fetchQuestionsFromCollectionId(this.state.collecId));

    this.closeMoveQuestionModal();
  };

  render() {
    const MAX_LENGTH = 350;
    const questionEntries = [];
    const { userTankID, userClientID, teamMembers, userRole, currentTeamData } =
      this.props;
    const {
      questionsData,
      quesTitle,
      quesDesc,
      collecId,
      openEditQuestionModal,
      answerModalOpen,
      loading,
      followedData,
      followLabel,
      collectionTitle,
      collectionDescription,
      collectionColor,
      collectionCreator,
      collectionCreatedAt,
      collectionUpdatedAt,
      expanded,
      showMoveQuestionModal,
      isDeleteInProgress,
    } = this.state;

    const maxDescriptionLength = collectionDescription?.length > MAX_LENGTH;

    questionsData &&
      questionsData.questions &&
      questionsData.questions.forEach((ques) => {
        const userDP =
          teamMembers.length &&
          teamMembers.filter((member) => {
            if (member.tankUserID === ques.tankUserID) {
              return member.dp && member.dp;
            }
          });

        questionEntries.push({
          quesId: ques?.quesId,
          quesTitle: ques?.quesTitle,
          userId: ques?.userId,
          userName: ques?.userName,
          answers: ques?.answers.length,
          collecId: ques?.collectionId,
          origin: ques?.origin,
          createdAt: ques?.createdAt,
          followed: ques?.followed ? ques?.followed : false,
          userDP:
            ques?.origin === ORIGIN_TANK
              ? ques?.creatorDetails?.[0]?.dp
              : ques?.slackCreatorDetails?.[0]?.avatar,
          creatorId: ques?.tankUserID,
        });
      });

    questionEntries?.length && mostRecentEntriesFirst(questionEntries);

    return (
      <>
        <div className="question-dash page-loader-pos dashboard-vert-gutters collection-questions">
          <div
            className={`${!loading ? "collection-info-wrapper" : ""} ${
              maxDescriptionLength ? "read-more-content" : ""
            } ${!expanded ? "" : "not-expanded"}`}
          >
            {loading ? (
              <Shimmer variant="rect" width="100%" height={197} />
            ) : (
              <>
                <div className="collection-info">
                  <div className="collection-copy">
                    <h1>{collectionTitle}</h1>
                    <p>{collectionDescription}</p>
                  </div>
                  {collectionDescription?.length > 350 ? (
                    <div
                      className={`read-more-btn ${expanded ? "expanded" : ""}`}
                    >
                      <button
                        onClick={this.handleExpandCollapse}
                        type="button"
                        class="flexbox-no-shrink pointer transition flexbox-centered radius-normal border-none no-outline shade-20 shade-10-on-hover background-transparent background-shade-50-on-hover background-shade-45-on-active no-selection"
                        style={{
                          display: "inline-flex",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="30"
                          height="30"
                          preserveAspectRatio="xMidYMid meet"
                          style={{ verticalAlign: "middle" }}
                        >
                          <g fill="currentColor">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.96967 13.7809C4.67678 13.488 4.67678 13.0131 4.96967 12.7202L9.68961 8.00027L4.96967 3.28033C4.67678 2.98744 4.67678 2.51256 4.96967 2.21967C5.26256 1.92678 5.73744 1.92678 6.03033 2.21967L11.2806 7.46994C11.5735 7.76283 11.5735 8.2377 11.2806 8.5306L6.03033 13.7809C5.73744 14.0738 5.26256 14.0738 4.96967 13.7809Z"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="collection-modify-info">
                  <p className="modify-info">
                    <span>Created by {collectionCreator}</span>, last modified{" "}
                    <span>{formatDateFromNow(collectionUpdatedAt)}</span>
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="collection-queries-container">
            {loading ? (
              <div className="queries-heading">
                <Shimmer variant="rect" width={110} height={45} />
              </div>
            ) : questionEntries.length > 0 ? (
              <div className="queries-heading">
                <h1>
                  Queries <span>{questionEntries?.length}</span>
                </h1>
              </div>
            ) : null}
            {loading ? (
              <TableShimmer variant="rect" width="100%" />
            ) : questionEntries.length > 0 ? (
              <div className="table-card">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Responses</th>
                      <th>Origin</th>
                      <th>Author</th>
                      <th>Actions</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionEntries.map((entry) => {
                      const {
                        quesId,
                        quesTitle,
                        userName,
                        answers,
                        collecId,
                        origin,
                        createdAt,
                        followed,
                        userDP,
                        creatorId,
                      } = entry;
                      let answerText =
                        answers === 0 || answers > 1 ? RESPONSES : RESPONSE;

                      return (
                        <Question
                          {...this.props}
                          currentTeamData={currentTeamData}
                          key={quesId}
                          quesId={quesId}
                          collecId={collecId}
                          quesTitle={quesTitle}
                          origin={origin}
                          createdAt={createdAt}
                          userName={userName}
                          answers={answers}
                          answerText={answerText}
                          handleAnswerModalOpen={this.handleAnswerModalOpen}
                          handleQuestionDelete={this.handleQuestionDelete}
                          renderEditQuestionModal={this.renderEditQuestionModal}
                          userTankID={userTankID}
                          userClientID={userClientID}
                          followedData={followedData}
                          fetchFollowedQueries={this.fetchFollowedQueries}
                          toRenderData={true}
                          isFollowed={followed}
                          toRenderCollectionName={false}
                          handleFollowQuestion={this.handleFollowQuestion}
                          handleUnfollowQuestion={this.handleUnfollowQuestion}
                          followLabel={followLabel}
                          openMoveQuestionModal={this.openMoveQuestionModal}
                          userDP={userDP}
                          userRole={userRole}
                          creatorId={creatorId}
                          isDeleteInProgress={isDeleteInProgress}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="no-questions-added">
                <h2 className="no-ques-title no-data">{NO_QUERIES}</h2>
                <NewQuestionButton
                  handleOnClick={this.props.renderModal}
                  btnText={`+ ${ADD_NEW_QUERY}`}
                />
              </div>
            )}
          </div>
        </div>
        {!isEmptyObject(quesDesc) ? (
          <EditQuestionModal
            quesTitle={quesTitle}
            quesDesc={quesDesc}
            collecID={collecId}
            updateQues={this.handleEditQuestion}
            close={this.closeEditQuestionModal}
            open={openEditQuestionModal}
            collectionTitle={collectionTitle}
            userClientID={userClientID}
            userTankID={userTankID}
            handleQuestionSaveDraft={this.handleQuestionSaveDraft}
          />
        ) : null}
        {answerModalOpen ? (
          <AddAnswerModal
            addAns={this.handleAnswerAdd}
            currentTeamData={currentTeamData}
            open={answerModalOpen}
            close={this.handleAnswerModalClose}
            handleAnswerSaveDraft={this.handleAnswerSaveDraft}
          />
        ) : null}
        {showMoveQuestionModal ? (
          <MoveQuestionModal
            close={this.closeMoveQuestionModal}
            open={showMoveQuestionModal}
            collectionTitle={collectionTitle}
            userClientID={userClientID}
            currentCollecId={collecId}
            userTankID={userTankID}
            handleMoveQuestionToCollection={this.handleMoveQuestionToCollection}
          />
        ) : null}
      </>
    );
  }
}

export default CollectionsPage;
