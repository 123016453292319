import React, { Component } from "react";

import {
  getFollowedQueries,
  followQuery,
  unfollowQuery,
  questionDetails,
  getCollectionDetailsByID,
  updateQuestionByID,
  deleteQuestionAPI,
  addAnswer,
} from "../../api/api";
import axios from "axios";

import Question from "../../components/Question/Question";

import EditQuestionModal from "../../components/Modals/EditQuestionModal/EditQuestionModal";
import AddAnswerModal from "../../components/Modals/AddAnswerModal/AddAnswerModal";
import MoveQuestionModal from "../../components/Modals/MoveQuestionModal/MoveQuestionModal";

import TableShimmer from "../../components/Shimmer/TableShimmer";

import { RESPONSE, RESPONSES } from "../../constants/Answer";

import { saveQuestionAsDraft, saveAnswerAsDraft } from "../../utils";
import { ORIGIN_TANK } from "../../constants";

import "./InterestsPage.scss";
class InterestsPage extends Component {
  state = {
    questionsData: [],
    loading: true,
    openEditQuestionModal: false,
    questionId: "",
    quesTitle: "",
    quesDesc: "",
    collecId: "",
    quesCollectionId: "",
    collectionTitle: "",
    answerModalOpen: false,
    openMoveQuesModal: false,
    moveQuestionId: "",
  };

  componentDidMount() {
    const { userTankID, userClientID } = this.props;
    this.fetchFollowedQueries(userTankID, userClientID);
  }

  handleQuestionPrefill = async (id) => {
    if (this.state.openEditQuestionModal === false) {
      this.setState({
        quesTitle: "",
        quesDesc: "",
        collecId: "",
        collectionTitle: "",
      });
    }

    const data = await axios.get(`${questionDetails}${id}`);
    const quesDetails = data?.data?.data?.[0];

    this.setState(
      {
        quesTitle: quesDetails.quesTitle,
        quesDesc: quesDetails?.metadata?.[0]?.savedData,
        collecId: quesDetails.collectionId,
      },
      async () => {
        const collectionData = await axios.get(
          `${getCollectionDetailsByID}${this.state.collecId}`
        );
        const collectionDetails =
          collectionData.data &&
          collectionData.data.data &&
          collectionData.data.data[0];

        this.setState({ collectionTitle: collectionDetails.collectionName });
      }
    );
  };

  closeEditQuestionModal = () => {
    this.setState({ openEditQuestionModal: false });
  };

  renderEditQuestionModal = (id, collecId) => {
    this.handleQuestionPrefill(id);
    this.setState({
      openEditQuestionModal: !this.state.openEditQuestionModal,
      questionId: id,
      quesCollectionId: collecId,
    });
  };

  handleEditQuestion = (queTitle, { quesDesc, savedData }, quesCollecId) => {
    const updatedQuestionData = {
      quesTitle: queTitle,
      quesDesp: quesDesc,
      collectionId: quesCollecId,
      savedData,
    };

    axios.patch(
      `${updateQuestionByID}${this.state.questionId}`,
      updatedQuestionData
    );
    this.setState({ openEditQuestionModal: !this.state.openEditQuestionModal });
  };

  handleQuestionSaveDraft = (
    queTitle,
    { quesDesc, savedData },
    quesCollecId
  ) => {
    const { userTankID, userClientID, creatorID, selectedMembers } = this.props;
    const { quesCollectionId } = this.state;
    let colId = "";
    if (quesCollecId === "") {
      colId = quesCollectionId;
    } else {
      colId = quesCollecId;
    }
    saveQuestionAsDraft(
      queTitle,
      quesDesc,
      colId,
      userTankID,
      creatorID,
      userClientID,
      selectedMembers,
      savedData
    );
    this.setState({ openEditQuestionModal: !this.state.openEditQuestionModal });
  };

  fetchFollowedQueries = async (tankID, spaceID) => {
    const LOADING_DELAY = 600;
    const followBody = {
      tankUserID: tankID,
      spaceID: spaceID,
    };
    const result = await axios.post(getFollowedQueries, followBody);

    const interestsData = result.data && result.data.data;

    if (interestsData) {
      this.setState({ questionsData: interestsData });
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, LOADING_DELAY);
  };

  handleAnswerModalClose = () => {
    this.setState({
      answerModalOpen: false,
      questionId: "",
    });
  };

  handleQuestionDelete = (quesID) => {
    const { userTankID, userClientID } = this.props;
    axios.delete(`${deleteQuestionAPI}${quesID}`);

    this.fetchFollowedQueries(userTankID, userClientID);
  };

  handleFollowQuestion = (quesId) => {
    const { userTankID, userClientID } = this.props;
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios.post(followQuery, body);
  };

  handleUnfollowQuestion = (quesId) => {
    const { userTankID, userClientID } = this.props;
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios
      .post(unfollowQuery, body)
      .then(() => this.fetchFollowedQueries(userTankID, userClientID));
  };

  handleAnswerModalOpen = (quesID, quesTitle) => {
    this.setState({
      answerModalOpen: true,
      questionId: quesID,
      quesTitle: quesTitle,
    });
  };

  handleAnswerAdd = ({ answerDesc, savedData }, userName) => {
    const { userTankID } = this.props;
    const addAnswerObj = {
      answerDesp: answerDesc,
      answeredBy: userName,
      tankUserID: userTankID,
      savedData,
    };
    axios.patch(`${addAnswer}${this.state.questionId}`, addAnswerObj);
    this.handleAnswerModalClose();
  };

  handleAnswerSaveDraft = ({ answerDesc, savedData }) => {
    const { userTankID, userClientID, creatorID } = this.props;
    const { questionId, quesTitle } = this.state;
    saveAnswerAsDraft(
      answerDesc,
      userTankID,
      userClientID,
      creatorID,
      questionId,
      quesTitle,
      savedData
    );
    this.handleAnswerModalClose();
  };

  closeMoveQuestionModal = () => {
    this.setState({ moveQuestionId: "", openMoveQuesModal: false });
  };

  openMoveQuestionModal = (id) => {
    this.setState({ moveQuestionId: id, openMoveQuesModal: true });
  };

  handleMoveQuestionToCollection = async (id) => {
    const updatedQuestionData = {
      collectionId: id,
    };

    await axios.patch(
      `${updateQuestionByID}${this.state.moveQuestionId}`,
      updatedQuestionData
    );

    this.closeMoveQuestionModal();
  };

  render() {
    const { userTankID, userClientID, currentTeamData, userRole, creatorId } =
      this.props;
    const questionEntries = [];
    this.state.questionsData.length > 0 &&
      this.state.questionsData.map((ques) => {
        questionEntries.push({
          quesId: ques.quesId,
          quesTitle: ques.quesTitle,
          userId: ques.userId,
          userName: ques.userName,
          answers: ques.answers.length,
          collecId: ques.collectionId,
          origin: ques.origin,
          createdAt: ques.createdAt,
          followed: true,
          quesCreatorDp:
            ques?.origin === ORIGIN_TANK
              ? ques?.creatorDetails?.[0]?.dp
              : ques?.slackCreatorDetails?.[0]?.avatar,
        });
      });

    return (
      <>
        <div className="interests-page page-top">
          <div className="question-dash page-loader-pos">
            {this.state.loading ? (
              <TableShimmer variant="rect" width="100%" />
            ) : questionEntries && questionEntries.length ? (
              <div className="table-card">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Responses</th>
                      <th>Origin</th>
                      <th>Collection</th>
                      <th>Creator</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionEntries.map((entry) => {
                      const {
                        quesId,
                        quesTitle,
                        userName,
                        answers,
                        collecId,
                        origin,
                        createdAt,
                        followed,
                        quesCreatorDp,
                      } = entry;
                      let answerText =
                        answers === 0 || answers > 1 ? RESPONSES : RESPONSE;

                      return (
                        <Question
                          currentTeamData={currentTeamData}
                          userRole={userRole}
                          creatorId={creatorId}
                          key={quesId}
                          quesId={quesId}
                          collecId={collecId}
                          quesTitle={quesTitle}
                          userName={userName}
                          answers={answers}
                          answerText={answerText}
                          handleAnswerModalOpen={this.handleAnswerModalOpen}
                          handleQuestionDelete={this.handleQuestionDelete}
                          renderEditQuestionModal={this.renderEditQuestionModal}
                          isFollowed={followed}
                          userTankID={userTankID}
                          userClientID={userClientID}
                          toRenderData={true}
                          fetchFollowedQueries={this.fetchFollowedQueries}
                          origin={origin}
                          createdAt={createdAt}
                          handleFollowQuestion={this.handleFollowQuestion}
                          handleUnfollowQuestion={this.handleUnfollowQuestion}
                          toRenderCollectionName={true}
                          openMoveQuestionModal={this.openMoveQuestionModal}
                          hasModifyOptions={false}
                          userDP={quesCreatorDp}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="no-interests-added">
                <div className="no-interests-asset">
                  <img
                    src={require("../../assets/images/no-interests.png")}
                    alt="no interests"
                  />
                </div>
                <h1>No interests added</h1>
              </div>
            )}
          </div>
        </div>
        {this.state.openEditQuestionModal ? (
          <EditQuestionModal
            quesTitle={this.state.quesTitle}
            quesDesc={this.state.quesDesc}
            collecID={this.state.collecId}
            updateQues={this.handleEditQuestion}
            close={this.closeEditQuestionModal}
            open={this.state.openEditQuestionModal}
            collectionTitle={this.state.collectionTitle}
            userClientID={userClientID}
            userTankID={userTankID}
            handleQuestionSaveDraft={this.handleQuestionSaveDraft}
          />
        ) : null}
        {this.state.answerModalOpen ? (
          <AddAnswerModal
            addAns={this.handleAnswerAdd}
            currentTeamData={currentTeamData}
            open={this.state.answerModalOpen}
            close={this.handleAnswerModalClose}
            handleAnswerSaveDraft={this.handleAnswerSaveDraft}
          />
        ) : null}
        {this.state.openMoveQuesModal ? (
          <MoveQuestionModal
            close={this.closeMoveQuestionModal}
            open={this.state.openMoveQuesModal}
            collectionTitle={this.state.collectionTitle}
            userClientID={userClientID}
            currentCollecId={this.state.collecId}
            userTankID={userTankID}
            handleMoveQuestionToCollection={this.handleMoveQuestionToCollection}
          />
        ) : null}
      </>
    );
  }
}

export default InterestsPage;
