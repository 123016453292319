import React, { useState, useEffect } from "react";
import { ADD_RESPONSE_TITLE } from "../../../constants/Answer";
import { CANCEL, ADD } from "../../../constants/ButtonLabel";

// import TankEditor from "../../TankEditor/TankEditor";
import TankEditorTool from "../../TankEditor/Editor";
import Modal from "../Modal/Modal";
import { convertToHTMLString } from "../../TankEditor/convertToHtmlString";

import "./AddAnswerModal.scss";

const AddAnswerModal = (props) => {
  const [editorContent, setEditorContent] = useState({});
  const [isAnswerAdded, setIsAnswerAdded] = useState(false);
  const [editorTool, setEditorTool] = useState();

  const {
    open,
    currentTeamData,
    close,
    addAns,
    handleAnswerSaveDraft,
    feedbackMsgEnabled,
    feedbackMsg,
  } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  useEffect(() => {
    if (!open) {
      setEditorContent("");
    }
  }, [open]);

  const handleEditorContent = (isDraftAnswer = false) => {
    editorTool
      ?.save()
      .then((outputData) => {
        const parsedData = convertToHTMLString(outputData?.blocks);
        if (!isDraftAnswer) {
          addAns(
            { savedData: outputData, answerDesc: parsedData },
            currentTeamData?.name
          );
        } else {
          handleAnswerSaveDraft(
            { savedData: outputData, answerDesc: parsedData },
            "add"
          );
        }
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  // const handleEditorChange = (content, editor) => {
  //   handleEditorContent();
  // };

  return (
    <Modal
      modalTitle={ADD_RESPONSE_TITLE}
      modalDisplay={divStyle}
      close={close}
      // isDisabled={false}
      handleProvisioning={() => handleEditorContent(false)}
      cancelText={CANCEL}
      provisionType={ADD}
      handleSaveAsDraft={() => handleEditorContent(true)}
      isDraftBtn={true}
      // isDraftBtnDisabled={false}
      feedbackMsgEnabled={feedbackMsgEnabled}
      feedbackMsg={feedbackMsg}
    >
      {/* <TankEditor content={editorContent} editorChange={handleEditorChange} /> */}
      <TankEditorTool
        // editorContentChange={handleEditorChange}
        setEditor={setEditorTool}
        editorTool={editorTool}
      />
    </Modal>
  );
};

export default AddAnswerModal;
